import React from "react"
import ImageText from "../../components/ImageText"

import "./MarcusHill.scss"

import image from "../../assets/partners/marcushill.jpg"
import webp from "../../assets/partners/marcushill.webp"

const MarcusHill = () => {
    return (
        <section id="ourwork-marcushill">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={image}
                    webpSrc={webp}
                    borderColor="#b7916d"
                    heading="Marcus Hill Apartments"
                    hrText={["aesthetics", "work"]}
                    content={
                        <p>
                            Your home away from home is located in Area 43,
                            Lilongwe and are stunning self-catering luxury
                            apartments. These apartments come fully themed and
                            fully furnished in contemporary Italian
                            furniture and style.
                        </p>
                    }
                    alt="marcus hill"
                    buttonText="VIEW WORK"
                    buttonUrl="/ourwork/marcushill"
                    url="/ourwork/marcushill"
                    reload
                />
                {/* <ZoomCarousel
                    images={[
                        { src: showcase1, type: "image", title: "Photography" },
                        { src: showcase2, type: "video", title: "Video" },
                        {
                            src: showcase3,
                            type: "image",
                            title: "Social Media",
                        },
                    ]}
                /> */}
            </div>
        </section>
    )
}

export default MarcusHill
