import React from "react"
import ImageText from "../../components/ImageText"

import "./Section2.scss"

import image from "../../assets/ourstory.jpg"

const Section2 = () => {
    return (
        <section id="about-section2">
            <div className="our-story contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    borderColor="#b7916d"
                    heading="WHAT'S OUR STORY?"
                    hrText={[
                        "aesthetics",
                        "about",
                    ]}
                    content={
                        <p>
                            A group of young
                            African creatives got
                            together to form an
                            agency that helps
                            brands discover their
                            brand voice. This
                            group combines their
                            diverse international
                            experience and passion
                            in marketing to
                            produce colourful work
                            for emerging and
                            established
                            businesses. This work
                            is driven by
                            simplicity, elegance,
                            and creativity and it
                            covers a range of
                            disciplines from
                            design and branding to
                            influencer and events
                            management.
                        </p>
                    }
                    alt="our story"
                />
            </div>
        </section>
    )
}

export default Section2
