import React from "react"

import "./Header.scss"

interface HeaderProps {
    heading: React.ReactNode
    subHeading?: React.ReactNode
    content?: React.ReactNode
    buttonText?: React.ReactNode
    buttonURL?: string
}

const Header = ({
    heading,
    subHeading,
    content,
    buttonText,
    buttonURL,
}: HeaderProps) => {
    return (
        <div className="header">
            <h1>{heading}</h1>
            {subHeading ? (
                <h2 className="text-center">
                    {subHeading}
                </h2>
            ) : (
                ""
            )}
            {content && <>{content}</>}
            {buttonText && (
                <a
                    href={buttonURL}
                    className="mt-4 border-2 border-beige rounded-full text-beige font-medium hover:bg-beige hover:text-camel-dark transition-colors flex items-center"
                >
                    {buttonText}
                    <i className="fa-solid fa-chevron-right text-[inherit] ml-1 md:ml-2 relative top-[0.05rem] "></i>
                </a>
            )}
        </div>
    )
}

export default Header
