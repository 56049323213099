import React from "react"

import ImageText from "../../components/ImageText"

import consultancyImage from "../../assets/consultancy.png"
import webp from "../../assets/consultancy.webp"

const Section4 = () => {
    return (
        <section id="home-section4">
            <div className="contain">
                <ImageText
                    textAlign="left"
                    direction="invert"
                    src={consultancyImage}
                    webpSrc={webp}
                    borderColor="#603012"
                    alt="girl flower brown square"
                    buttonText="LEARN MORE"
                    buttonUrl="/services#services-consultancy"
                    hrText={["aesthetics", "home"]}
                    heading={
                        <React.Fragment>
                            CONSULTANCY &<br />
                            BRAND MANAGEMENT
                        </React.Fragment>
                    }
                    content={
                        <React.Fragment>
                            <p>
                                Brand consulting through analysis and
                                understanding of your brand's digital footprint.
                                Tailored to your vision, we develop:
                                <br />
                                <br />
                                <li>Brand communications and design</li>
                                <li>Brand positioning and strategy</li>
                                <li>Brand monitoring</li>
                                <li>Naming convention</li>
                            </p>
                        </React.Fragment>
                    }
                />
            </div>
        </section>
    )
}

export default Section4
