import React from "react"
import { useParams } from "react-router"
import { Helmet } from "react-helmet"

import Footer from "../components/Footer"
import Section from "./ClientWork/Section"
import Section1 from "./ClientWork/Section1"

import clientData from "./ClientWork/clientData.json"
import NotFound from "./NotFound"
// import BackgroundImage from "../components/BackgroundImage"

const ClientWork = () => {
    const params = useParams<"clientID">()
    const currentClient = clientData.find((client) => {
        return client.id === params.clientID
    })

    if (currentClient) {
        return (
            <section id="clientwork">
                <Section1
                    background={currentClient.headerImage}
                    heading={currentClient.clientName}
                />
                {currentClient.work.map((work, index) => (
                    <React.Fragment key={`${currentClient.id}work${index}`}>
                        <Helmet>
                            <title>{currentClient.clientName}</title>
                        </Helmet>
                        <Section
                            direction={index % 2 === 0 ? "normal" : "invert"}
                            textAlign={index % 2 === 0 ? "right" : "left"}
                            src={work.src}
                            type={work.type as "image" | "video"}
                            backgroundColor=""
                            borderColor={work.borderColor || "#b7916d"}
                            showBorder={false}
                            heading={work.heading}
                            content={work.content}
                            clientID={currentClient.id}
                            alt={work.heading}
                            key={`${currentClient.id}work${index}`}
                        />
                        {/* <section id="client-section1">            
                            <BackgroundImage
                                src={currentClient.headerImage}
                                children=""
                            />
                        </section> */}
                    </React.Fragment>
                ))}
                <Footer />
            </section>
        )
    }

    return <NotFound />
}

export default ClientWork
