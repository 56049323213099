import React from "react"
import ImageText from "../../components/ImageText"
import socialMediaImage from "../../assets/socialmedia.png"
import webp from "../../assets/socialmedia.webp"

const Section6 = () => {
    return (
        <section id="home-section6">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={socialMediaImage}
                    webpSrc={webp}
                    alt="social media"
                    borderColor="#d4c5b9"
                    buttonText="LEARN MORE"
                    buttonUrl="/services#services-branding"
                    hrText={["aesthetics", "home"]}
                    heading={<React.Fragment>SOCIAL MEDIA</React.Fragment>}
                    content={
                        <p>
                            Social media communication cadence, content guidance
                            and success measurement framework
                        </p>
                    }
                />
            </div>
        </section>
    )
}

export default Section6
