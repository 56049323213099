import React from "react"
import { Helmet } from "react-helmet"

import Survey from "../Contact/Survey"
import { SurveyContextProvider } from "../Contact/context/SurveyContext"

const Message = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <SurveyContextProvider>
                <Survey />
            </SurveyContextProvider>
        </React.Fragment>
    )
}

export default Message
