import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
// import ReactGA from "react-ga"

import App from "./App"

import "./index.scss"

import reportWebVitals from "./reportWebVitals"
import { sendToVercelAnalytics } from "./vitals"

// const TRACKING_ID = "G-791ZPPV02Y"
// ReactGA.initialize(TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToVercelAnalytics)
