import React from "react"

interface IMGProps {
    imgSrc: string
    alt: string
    webpSrc?: string
    className?: string
}

const IMG = ({ imgSrc, alt, className = "", webpSrc = "" }: IMGProps) => {
    return (
        <picture>
            {webpSrc !== "" && <source srcSet={webpSrc} type="image/webp" />}
            <img className={className} src={imgSrc} alt={alt} />
        </picture>
    )
}

export default IMG
