import React from "react"
import SectionHeading from "../components/SectionHeading"

import "./Home.scss"

import Section1 from "./Home/Section1"
import Section3 from "./Home/Section3"
import Section4 from "./Home/Section4"
import Section5 from "./Home/Section5"
import Section6 from "./Home/Section6"
import Section7 from "./Home/Section7"
import Section8 from "./Home/Section8"
import Footer from "../components/Footer"

import section3_4Image from "../assets/section2-3.webp"
import section5_6Image from "../assets/section4-5.webp"
import section7_8Image from "../assets/section6-7.webp"
import Section10 from "./Home/Section10"
import Section2 from "./Home/Section2"

const Home = () => {
    return (
        <React.Fragment>
            <Section1 />
            <Section2 />
            <SectionHeading
                imageSrc={section3_4Image}
                heading1="EVENTS"
                heading2="CONSULTING"
            />
            <Section3 />
            <Section4 />
            <SectionHeading
                imageSrc={section5_6Image}
                heading1="BRANDING"
                heading2="SOCIAL MEDIA"
            />
            <Section5 />
            <Section6 />
            <SectionHeading
                imageSrc={section7_8Image}
                heading1="DESIGN"
                heading2="APP//WEB DEV"
            />
            <Section7 />
            <Section8 />
            <Section10 />
            <Footer />
        </React.Fragment>
    )
}

export default Home
