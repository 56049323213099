import React from "react"
import { Routes, Route } from "react-router-dom"
// import ReactGA from "react-ga"

import Home from "./screens/Home"
import Services from "./screens/Services"
import About from "./screens/About"
import Contact from "./screens/Contact"

import NavBar from "./components/NavBar"

import "./App.scss"
import NotFound from "./screens/NotFound"
import Received from "./screens/Received"
import OurWork from "./screens/OurWork"
import Influencers from "./screens/Influencers"
import ClientWork from "./screens/ClientWork"
import Message from "./screens/Contact/Message"

function App() {
    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname + window.location.search)
    // }, [])

    return (
        <React.Fragment>
            <div id="global-modal"></div>
            <NavBar />
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/services"
                    element={<Services />}
                />
                <Route
                    path="/ourwork"
                    element={<OurWork />}
                />
                <Route
                    path="/ourwork/:clientID"
                    element={<ClientWork />}
                />
                <Route
                    path="/about"
                    element={<About />}
                />
                <Route
                    path="/influencers"
                    element={<Influencers />}
                />
                <Route
                    path="/contact"
                    element={<Contact />}
                />
                <Route
                    path="/contact/message"
                    element={<Message />}
                />
                <Route
                    path="/received"
                    element={<Received />}
                />
                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
        </React.Fragment>
    )
}

export default App
