import React from "react"
import ImageText from "../../components/ImageText"

import "./Section2.scss"

import image from "../../assets/ourstory.jpg"

const Section2 = () => {
    return (
        <section id="contact-section2">
            <div className="contact contain">
                <ImageText
                    // buttonText="MESSAGE US"
                    // buttonUrl="/contact/message"
                    direction="invert"
                    textAlign="left"
                    src={image}
                    borderColor="#c1ab86"
                    map={
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe
                                    title="GoogleMap"
                                    id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=Plot%20253%20Mwanza,%20Lilongwe,%20Malawi&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight={
                                        0
                                    }
                                    marginWidth={
                                        0
                                    }
                                ></iframe>
                            </div>
                        </div>
                    }
                    heading="OUR CONTACTS"
                    hrText={[
                        "aesthetics",
                        "contacts",
                    ]}
                    content={
                        <p>
                            <strong>
                                Address:
                            </strong>
                            <br />
                            Aesthetics Creative
                            Agency
                            <br />
                            Plot 253
                            <br />
                            Mwanza, Area 15
                            <br />
                            Lilongwe, Malawi
                            <br />
                            <br />
                            <strong>
                                Phone:
                            </strong>
                            <br />
                            +265 883 92 30 22
                            <br />
                            +353 86 224 8211
                            <br />
                            <br />
                            <strong>
                                Email:
                            </strong>
                            <br />
                            aestheticsmalawi@gmail.com
                        </p>
                    }
                    alt="contact us"
                />
            </div>
        </section>
    )
}

export default Section2
