import React from "react"
import ImageText from "../../components/ImageText"

import webDevImage from "../../assets/webdev.png"
import webp from "../../assets/webdev.webp"

const Section8 = () => {
    return (
        <section id="home-section8">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={webDevImage}
                    webpSrc={webp}
                    borderColor="#a4785b"
                    alt="web development"
                    buttonText="LEARN MORE"
                    buttonUrl="/services#services-design"
                    hrText={["aesthetics", "home"]}
                    heading={<React.Fragment>WEB/APP DEV</React.Fragment>}
                    content={
                        <p>
                            Webpage and mobile app development that offers
                            beautifully designed and easy to navigate user
                            interfaces
                        </p>
                    }
                />
            </div>
        </section>
    )
}

export default Section8
