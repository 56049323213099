import React, { useReducer } from "react"
import { Link } from "react-router-dom"

import "./NavBar.scss"
import logo from "../assets/logo.svg"
import menuIcon from "../styles/icons/menu.svg"

const NavBarLinks = () => {
    return (
        <React.Fragment>
            <ul className="navbar-links">
                <li>
                    <Link reloadDocument to="/">
                        Home
                    </Link>
                </li>
                <li>
                    <Link reloadDocument to="/ourwork">
                        Our Work
                    </Link>
                </li>
                <li>
                    <Link reloadDocument to="/services">
                        Services
                    </Link>
                </li>
                <li>
                    <Link reloadDocument to="/about">
                        About
                    </Link>
                </li>
                <li>
                    <Link reloadDocument to="/influencers">
                        Influencers
                    </Link>
                </li>
                <li>
                    <Link reloadDocument to="/contact">
                        Contact Us
                    </Link>
                </li>
            </ul>
        </React.Fragment>
    )
}

const NavBar = () => {
    const [navbarMobile, toggleNavbarMobile] = useReducer(
        (navbarMobile) => !navbarMobile,
        false
    )

    return (
        <React.Fragment>
            <nav
                className={`navbar-mobile ${
                    navbarMobile ? "navbar-mobile-show" : ""
                }`}
            >
                <div className="navbar-mobile-body">
                    <NavBarLinks />
                </div>
            </nav>

            <nav className="navbar">
                <div className="navbar-body">
                    <div className="navbar-body-left">
                        <Link reloadDocument to="/">
                            <div>
                                <img
                                    src={logo}
                                    alt="aesthetics creative agency logo"
                                />
                                <span style={{ display: "none" }}>
                                    Aesthetics <br /> Creative
                                    <br />
                                    Agency
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="navbar-body-right">
                        <NavBarLinks />
                        <button onClick={toggleNavbarMobile}>
                            <img src={menuIcon} alt="menu" />
                        </button>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default NavBar
