import React, { useEffect } from "react"

import useIterator from "../hooks/useIterator"
import sleep from "../hooks/sleep"

import "./Greeting.scss"

const Greeting = () => {
    const [greeting, next] = useIterator([
        ["welcome.", "'Outfit',sans-serif"],
        ["TAKULANDIRANI", "'Oswald', sans-serif"],
        ["MAUYA", "'Bodoni Moda', serif"],
        ["أهلا", "'Cairo', sans-serif"],
        ["Bem-Vindo", "'Noto Serif Display', serif"],
        ["Re a go amogela", "'Changa', sans-serif"],
        ["mwaiseni", "'Josefin Sans', sans-serif"],
    ])

    useEffect(() => {
        const timeouts: NodeJS.Timeout[] = []
        const text = document.getElementById("greeting")

        if (text !== null) {
            ;(async () => {
                text.style.opacity = "1"
                timeouts.push(await sleep(3000))

                text.style.opacity = "0"
                timeouts.push(await sleep(1000))

                next()
            })()
        }

        return () => {
            if (timeouts.length) {
                timeouts.forEach((t) => {
                    clearTimeout(t)
                })
            }
        }
    }, [next, greeting])

    return (
        <h5 style={{ fontFamily: greeting[1] }} id="greeting">
            {greeting[0]}
        </h5>
    )
}

export default Greeting
