import React from "react"
import BackgroundImage from "../../components/BackgroundImage"

import Header from "../../components/Header"

import "./Section1.scss"

import background from "../../assets/contactcover.jpg"

const Section1 = () => {
    return (
        <section id="contact-section1">
            <BackgroundImage src={background}>
                <Header
                    heading="Contact Us"
                    buttonText="Work With Us"
                    buttonURL="/contact/message"
                />
            </BackgroundImage>
        </section>
    )
}

export default Section1
