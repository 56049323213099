import React from "react"
import ImageText from "../../components/ImageText"

import "./Maxwell.scss"

import image from "../../assets/team/maxwell.jpg"

const Maxwell = () => {
    return (
        <section id="about-maxwell">
            <div className="our-founder contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={image}
                    heading="Maxwell Kumwembe"
                    subheading="Business Developer"
                    borderColor="#ab9483"
                    hrText={["aesthetics", "about"]}
                    content={
                        <React.Fragment>
                            <p>
                                Maxwell Kumwembe studied and majored in
                                Marketing, and is currently working as
                                Aesthetics Creative Agency's business developer.
                                Maxwell says he sees a gap in the proper growth
                                of ideas that matter and seem to have a positive
                                impact in Malawi. His goal is fill this gap and
                                use his skills and knowledge to help these
                                ideas become reality.
                            </p>
                        </React.Fragment>
                    }
                    alt="Maxwell Kumwembe"
                />
            </div>
        </section>
    )
}

export default Maxwell
