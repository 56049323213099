import React from "react"

import "./SectionHeading.scss"

interface SectionHeadingProps {
    heading1: string
    heading2: string
    imageSrc: string
}

const SectionHeading = ({
    heading1,
    heading2,
    imageSrc,
}: SectionHeadingProps) => {
    return (
        <section className="section-heading">
            <div
                style={{ backgroundImage: `url(${imageSrc})` }}
                className="section-heading-cover"
            >
                <div className="section-heading-content">
                    <div className="contain">
                        <div className="section-heading-container">
                            <h2>{heading1}</h2>
                            <h2>&nbsp;&#47;&#47;</h2>
                            <h2>{heading2}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionHeading
