import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"

import Section1 from "./OurWork/Section1"
import Khala from "./OurWork/Khala"
import Latitude from "./OurWork/Latitude"
import Khichinihub from "./OurWork/Khichinihub"
import MarcusHill from "./OurWork/MarcusHill"
import YourMeatGuys from "./OurWork/YourMeatGuys"
import SectionHeading from "../components/SectionHeading"
import clientsImage from "../assets/section6-7.jpg"
// import PastWork from "./OurWork/PastWork"

const OurWork = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Our Work</title>
            </Helmet>

            <Section1 />
            <SectionHeading
                heading1="Present"
                heading2="Clients"
                imageSrc={clientsImage}
            />
            {/* <PastWork heading="Present" src={clientsImage} /> */}
            <Latitude />
            <SectionHeading
                heading1="Past"
                heading2="Clients"
                imageSrc={clientsImage}
            />
            {/* <PastWork heading="Past" src={clientsImage} /> */}
            <Khala />
            <Khichinihub />
            <MarcusHill />
            <YourMeatGuys />
            <Footer />
        </React.Fragment>
    )
}

export default OurWork
