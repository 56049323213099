import React from "react"
import ImageText from "../../components/ImageText"

import "./Headline.scss"

import image from "../../assets/ourservices.jpg"

const Headline = () => {
    return (
        <section id="services-headline">
            <div className="our-story contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    borderColor="#452e14"
                    heading="OUR SERVICES"
                    hrText={["aesthetics", "about"]}
                    content={
                        <p>
                            At Aesthetics Creative Agency, we believe in the
                            power of simplicity, elegance, and creativity. Our
                            services are designed to bring out the best in your
                            business and help you stand out from the crowd. From
                            branding to events management, we have the expertise
                            and experience to elevate your business to new
                            heights. Let us guide you on a journey towards
                            success, where simplicity meets sophistication and
                            creativity knows no bounds.
                        </p>
                    }
                    alt="headline"
                />
            </div>
        </section>
    )
}

export default Headline
