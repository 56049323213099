import React from "react"

import ImageText from "../../components/ImageText"

import consultancyImage from "../../assets/consultancy.png"
import webp from "../../assets/consultancy.webp"

const Consultancy = () => {
    return (
        <section id="services-consultancy">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={consultancyImage}
                    webpSrc={webp}
                    borderColor="#603012"
                    alt="girl flower brown square"
                    hrText={["aesthetics", "home"]}
                    heading={
                        <React.Fragment>
                            CONSULTANCY &<br />
                            BRAND MANAGEMENT
                        </React.Fragment>
                    }
                    content={
                        <React.Fragment>
                            <p>
                                Unleash the full potential of your brand with
                                Aesthetics Creative Agency's consultancy and
                                brand management services. Our team of experts
                                will work closely with you to understand your
                                vision, analyze your market, and develop a
                                customized strategy that elevates your brand to
                                new heights. From branding to marketing and
                                beyond, we have the experience and expertise to
                                help you achieve your goals and reach your
                                target audience. Trust us to help you manage and
                                grow your brand with ease and elegance.
                            </p>
                        </React.Fragment>
                    }
                />
            </div>
        </section>
    )
}

export default Consultancy
