import React, { useEffect, useState } from "react"
import BackgroundImage from "../components/BackgroundImage"
import Header from "../components/Header"

import "./Received.scss"

import background from "../assets/contactcover.jpg"

const Received = () => {
    const [message, setMessage] = useState(
        <>Your message has been received</>,
    )
    const [heading, setHeading] =
        useState("Thank You")

    useEffect(() => {
        setTimeout(() => {
            setHeading("")
            setMessage(
                <>
                    Our team are currently
                    reviewing your message <br />{" "}
                    and will be in touch with you
                    within 5 working days
                </>,
            )
        }, 3000)
    }, [])

    return (
        <section id="received">
            <BackgroundImage
                src={background}
                children={
                    <Header
                        heading={heading}
                        subHeading={message}
                    />
                }
            />
        </section>
    )
}

export default Received
