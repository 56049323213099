import React from "react"

import downChevron from "../styles/icons/chevron-down.svg"

import "./EnterButton.scss"

interface EnterButtonProps {
    elementID: string
}

const EnterButton = ({ elementID: elementClassName }: EnterButtonProps) => {
    return (
        <span
            onClick={() => {
                document.getElementById(elementClassName)?.scrollIntoView(false)
            }}
            className="enter-button"
        >
            <img src={downChevron} alt="enter button" />
        </span>
    )
}

export default EnterButton
