import React from "react"
import ImageText from "../../components/ImageText"

import graphicDesignImage from "../../assets/graphicdesign.jpg"
import webp from "../../assets/graphicdesign.webp"

const Section7 = () => {
    return (
        <section id="home-section7">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={graphicDesignImage}
                    webpSrc={webp}
                    borderColor="#d7ae89"
                    alt="graphic design"
                    buttonText="LEARN MORE"
                    buttonUrl="/services#services-design"
                    hrText={["aesthetics", "home"]}
                    heading={<React.Fragment>GRAPHIC DESIGN</React.Fragment>}
                    content={
                        <p>
                            Social media creative, posters, brochures, business
                            cards, pamphlets, logos, and <strong>UI/UX</strong>
                        </p>
                    }
                />
            </div>
        </section>
    )
}

export default Section7
