import React from "react"
import BackgroundImage from "../../components/BackgroundImage"

import Header from "../../components/Header"

import "./Section1.scss"

import background from "../../assets/aboutcover.jpg"

const Section1 = () => {
    return (
        <section id="about-section1">
            <BackgroundImage src={background}>
                <Header heading="About Us" />
            </BackgroundImage>
        </section>
    )
}

export default Section1
