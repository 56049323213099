import React from "react"
import ImageText from "../../components/ImageText"

import "./Khichinihub.scss"

import image from "../../assets/partners/khichinihub.jpg"
import webp from "../../assets/partners/khichinihub.webp"

const Khichinihub = () => {
    return (
        <section id="ourwork-khichinihub">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    webpSrc={webp}
                    borderColor="#b7916d"
                    heading="Khichini Hub"
                    hrText={["aesthetics", "work"]}
                    content={
                        <p>
                            In the heart of Lilongwe, resides a little space
                            within the city that offers serenity and sanctity
                            from the everyday business of daily life. Khichini
                            Hub is a space where culture meets food and where
                            memories are made. The space offers a fusion of
                            foods from all over the world, including local
                            delicacies, which are supplemented by weekly live
                            entertainment and beautiful coworking spaces.
                        </p>
                    }
                    alt="khichini hub"
                    buttonText="VIEW WORK"
                    buttonUrl="/ourwork/khichinihub"
                    url="/ourwork/khichinihub"
                    reload
                />
                {/* <ZoomCarousel
                    images={[
                        { src: showcase1, type: "image", title: "Design" },
                        { src: showcase2, type: "video", title: "Reels" },
                        {
                            src: showcase3,
                            type: "image",
                            title: "Social Media",
                        },
                    ]}
                /> */}
            </div>
        </section>
    )
}

export default Khichinihub
