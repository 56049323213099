import React from "react"
import BackgroundImage from "../../components/BackgroundImage"

import Header from "../../components/Header"

import "./Section1.scss"

const Section1 = ({
    heading,
    background,
}: {
    heading: string
    background: string
}) => {
    return (
        <section id="client-section1">
            <BackgroundImage src={background}>
                <Header heading={heading} />
            </BackgroundImage>
        </section>
    )
}

export default Section1
