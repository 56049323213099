import React from "react"
import ImageText from "../../components/ImageText"

import "./YourMeatGuys.scss"

import image from "../../assets/partners/yourmeatguys.jpg"
import webp from "../../assets/partners/yourmeatguys.webp"

const YourMeatGuys = () => {
    return (
        <section id="ourwork-yourmeatguys">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    webpSrc={webp}
                    borderColor="#b7916d"
                    heading="Your Meat Guys"
                    hrText={["aesthetics", "work"]}
                    content={
                        <p>
                            Somewhere in the Central Region of family exists a
                            butchery known as Your Meat Guys which supplies only
                            the best beef sourced from the lowlands of the Shire
                            Valley. Your Meat Guys is a family owned enterprise
                            which deeply values this sacred institution and
                            treats each and everyone of its many loyal
                            customers, as such. Your Meat Guys is dedicated to
                            supplying only the most organic beef.
                        </p>
                    }
                    alt="your meat guys"
                    buttonText="VIEW WORK"
                    buttonUrl="/ourwork/yourmeatguys"
                    url="/ourwork/yourmeatguys"
                    reload
                />
                {/* <ZoomCarousel
                    images={[
                        {
                            src: showcase1,
                            type: "image",
                            title: "Product Shoot",
                        },
                        {
                            src: showcase3,
                            type: "image",
                            title: "Social Media",
                        },
                    ]}
                /> */}
            </div>
        </section>
    )
}

export default YourMeatGuys
