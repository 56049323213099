import React from "react"
import ImageText from "../../components/ImageText"

import brandingImage from "../../assets/branding.png"
import webp from "../../assets/branding.webp"

const Branding = () => {
    return (
        <section id="services-branding">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={brandingImage}
                    webpSrc={webp}
                    alt="branding"
                    borderColor="#af6f4c"
                    hrText={["aesthetics", "home"]}
                    content={
                        <p>
                            Maximize your reach and connect with your audience
                            like never before with Aesthetics Creative Agency's
                            branding and social media services. Our team of
                            experts will work with you to develop a
                            comprehensive strategy that showcases your brand's
                            unique personality and connects you with your target
                            audience. From crafting captivating content to
                            engaging with your followers, we have the experience
                            and expertise to help you grow your brand and build
                            your online presence with simplicity, elegance, and
                            creativity. Join the social media revolution and get
                            ready to shine.
                        </p>
                    }
                    heading={
                        <React.Fragment>
                            BRANDING &<br />
                            SOCIAL MEDIA
                        </React.Fragment>
                    }
                />
            </div>
        </section>
    )
}

export default Branding
