import React from "react"
import ImageText from "../../components/ImageText"

import "./GraceTembo.scss"

import image from "../../assets/team/grace.jpg"

const Mark = () => {
    return (
        <section id="about-grace">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={image}
                    heading="Grace Tembo"
                    subheading="Interaction Designer and Photographer"
                    borderColor="#ab9483"
                    hrText={[
                        "aesthetics",
                        "about",
                    ]}
                    content={
                        <React.Fragment>
                            <p>
                                Grace got a major
                                in Interaction
                                Design and Minor
                                in Photography and
                                enjoys creating
                                unique, meaningful
                                digital
                                experiences using
                                knowledge of User
                                Interface and User
                                Experience Design.
                                Grace is well
                                known as a
                                professional
                                conceptual fashion
                                and portrait
                                photographer.
                                <br />
                                Her style of
                                conceptual
                                photography blends
                                in between
                                portraiture and
                                architecture,
                                which shows the
                                beauty and organic
                                expressions that
                                people have and a
                                balance between
                                nature and
                                infrastructure
                                that surrounds us.
                            </p>
                        </React.Fragment>
                    }
                    alt="Mark Ulaya"
                />
            </div>
        </section>
    )
}

export default Mark
