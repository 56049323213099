import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"

import "./Services.scss"

import Section1 from "./Services/Section1"
import Headline from "./Services/Headline"
import EventsBrand from "./Services/EventsBrand"
import Consultancy from "./Services/Consultancy"
import Design from "./Services/Design"
import Branding from "./Services/Branding"

const Services = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Services</title>
            </Helmet>
            <Section1 />
            <Headline />
            <EventsBrand />
            <Consultancy />
            <Branding />
            <Design />
            <Footer />
        </React.Fragment>
    )
}

export default Services
