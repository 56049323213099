import React from "react"
import ImageText from "../../components/ImageText"

import "./Section.scss"

interface SectionProps {
    direction: "normal" | "invert"
    textAlign: "left" | "right"
    src: string
    type: "image" | "video"
    backgroundColor: string
    borderColor: string
    showBorder?: boolean
    heading: string
    content: string
    clientID: string
    alt: string
}

const Section = ({
    direction,
    textAlign,
    src,
    type,
    backgroundColor,
    borderColor,
    showBorder = true,
    heading,
    content,
    clientID,
    alt,
}: SectionProps) => {
    return (
        <section id="clientwork-section" style={{ backgroundColor }}>
            <div className="contain">
                <ImageText
                    direction={direction}
                    textAlign={textAlign}
                    src={src}
                    type={type}
                    borderColor={borderColor}
                    showBorder={showBorder}
                    heading={heading}
                    hrText={["aesthetics", "work"]}
                    content={<p>{content}</p>}
                    alt={alt}
                    zoomable
                />
            </div>
        </section>
    )
}

export default Section
