import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"

import Section1 from "./About/Section1"
import Section2 from "./About/Section2"
import Daniel from "./About/Daniel"
import MonaLisa from "./About/MonaLisa"
import Maxwell from "./About/Maxwell"
import Desire from "./About/Desire"
import GraceTembo from "./About/GraceTembo"
import Mark from "./About/Mark"

const About = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>About Us</title>
            </Helmet>
            <Section1 />
            <Section2 />
            <Daniel />
            <MonaLisa />
            <Maxwell />
            <Desire />
            <GraceTembo />
            <Mark />
            <Footer />
        </React.Fragment>
    )
}

export default About
