import React from "react"
import ImageText from "../../components/ImageText"

import designImage from "../../assets/graphicdesign.jpg"

const Design = () => {
    return (
        <section id="services-design">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={designImage}
                    alt="design"
                    borderColor="#d7ae89"
                    hrText={["aesthetics", "home"]}
                    content={
                        <p>
                            Elevate your business to new heights with Aesthetics
                            Creative Agency's design services. Our team of
                            experts is passionate about design and creativity,
                            and is dedicated to helping you bring your vision to
                            life. Whether you're looking to redesign your
                            website, create a new logo, or develop an
                            advertising campaign, we have the experience and
                            expertise to help you achieve your goals. Trust us
                            to help you grow your business with simplicity,
                            elegance, and creativity.
                        </p>
                    }
                    heading={<React.Fragment>DESIGN</React.Fragment>}
                />
            </div>
        </section>
    )
}

export default Design
