import React from "react"
import ImageText from "../../components/ImageText"

import eventsBrandImage from "../../assets/eventsbrand.png"
import webp from "../../assets/eventsbrand.webp"

const Section3 = () => {
    return (
        <section id="home-section3">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    borderColor="#c7a47d"
                    src={eventsBrandImage}
                    webpSrc={webp}
                    alt="flowers"
                    hrText={["aesthetics", "home"]}
                    heading={
                        <React.Fragment>
                            EVENTS &<br />
                            BRAND ACTIVATIONS
                        </React.Fragment>
                    }
                    content={
                        <p>
                            Bespoke events and creative activations organised
                            with brand objectives at heart
                        </p>
                    }
                    buttonText="LEARN MORE"
                    buttonUrl="/services#services-eventsbrand"
                />
            </div>
        </section>
    )
}

export default Section3
