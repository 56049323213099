import {
    useContext,
    useEffect,
    useRef,
    useState,
} from "react"
import "./Survey.css"
import {
    SelectInput,
    SelectOption,
    TextInput,
} from "../../components/Input"
import {
    Step2FormDataType,
    SurveyContext,
    SurveyContextType,
} from "./context/SurveyContext"

import step2Questions from "./data/step2Questions.json"
import step2Influencers from "./data/step2Influencers.json"
import hoverText from "./data/hoverText.json"
import { SingleValue } from "react-select"
import {
    useNavigate,
    useRoutes,
} from "react-router"

const ProgressButton = ({
    id,
    disabled,
    onClick,
    text,
    type,
}: {
    id: string
    disabled: boolean
    onClick: () => void
    text: string
    type?: "submit" | "button"
}) => {
    return (
        <button
            id={id}
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={`text-xl sm:text-2xl font-semibold p-2 rounded-lg ${
                disabled
                    ? "bg-khaki-light"
                    : "bg-camel-dark hover:bg-khaki-dark"
            }  transition-colors duration-300 text-beige hover:text-[#fff]`}
        >
            {text}
        </button>
    )
}

const ProgressBar = ({
    progress,
}: {
    progress: number
}) => {
    return (
        <div className="bg-khaki-light rounded-lg mb-2 h-3 flex overflow-hidden">
            <span
                style={{
                    width: `${progress * 100}%`,
                }}
                className={`bg-camel-dark rounded-lg transition-all`}
            />
        </div>
    )
}

const SelectionButton = ({
    height,
    text,
    icon,
    selection,
    id,
    handleSelect,
}: {
    height: "sm" | "lg"
    text: string
    icon: string
    selection: string | Set<string>
    id: string
    handleSelect: (id: string) => void
}) => {
    return (
        <button
            type="button"
            onClick={() => handleSelect(id)}
            className={` ${
                height === "lg"
                    ? "mt-3 p-2"
                    : "mt-3 p-2"
            } rounded-lg border border-camel-dark hover:bg-camel-dark transition-colors duration-300 hover:text-[#fff] flex justify-between items-center ${
                typeof selection === "string"
                    ? selection === id
                        ? "bg-camel-dark text-[#fff]"
                        : "bg-transparent text-camel-dark"
                    : selection.has(id)
                    ? "bg-camel-dark text-[#fff]"
                    : "bg-transparent text-camel-dark"
            }`}
        >
            <span className="text-[inherit] font-normal">
                {text}
            </span>
            <i
                className={`${icon} text-[inherit]`}
            ></i>
        </button>
    )
}

const SelectionCheckbox = ({
    height,
    text,
    icon,
    selection,
    id,
    handleSelect,
}: {
    height: "sm" | "lg"
    text: string
    icon: string
    selection: string | Set<string>
    id: string
    handleSelect: (id: string) => void
}) => {
    return (
        <div
            title={
                text === "Brand Management" ||
                text ===
                    "Social Media Marketing" ||
                text === "Digital Marketing" ||
                text === "Email Marketing" ||
                text === "Web Development" ||
                text === "Design" ||
                text === "Consultancy" ||
                text === "Photography" ||
                text === "Videography"
                    ? hoverText[text]
                    : ""
            }
            onClick={() => handleSelect(id)}
            className={` ${
                height === "lg"
                    ? "mt-3 p-2"
                    : "mt-3 p-2"
            } rounded-lg border border-camel-dark transition-colors duration-300 cursor-pointer flex justify-between items-center ${
                typeof selection != "string" &&
                selection.has(id)
                    ? "bg-camel-dark text-beige transition-all"
                    : "text-camel-dark"
            }`}
        >
            <span className="text-[inherit] font-normal">
                <i
                    className={`${icon} text-[inherit] mr-2`}
                />
                {text}
            </span>
            <span
                className={`text-[inherit] font-normal border border-camel-dark rounded-md min-h-[1.5rem] min-w-[1.5rem] items-center justify-center flex ${
                    typeof selection !=
                        "string" &&
                    selection.has(id)
                        ? " bg-beige text-camel-dark transition-all"
                        : "bg-input text-transparent"
                }`}
            >
                {typeof selection != "string" &&
                    selection.has(id) && (
                        <i
                            className={`fa-solid fa-check text-[inherit]`}
                        />
                    )}
            </span>
        </div>
    )
}

const PageHeading = ({
    title,
}: {
    title: string
}) => {
    return (
        <h2 className="text-xl font-semibold text-camel-dark mb-3">
            {title}
        </h2>
    )
}

const Page1 = () => {
    const context = useContext(SurveyContext)

    const page1Data: {
        question: string
        answers: {
            id: string
            text: string
            icon: string
        }[]
    } = {
        question:
            "Is this for you or your business?",
        answers: [
            {
                id: "you",
                text: "For you",
                icon: "fas fa-user",
            },
            {
                id: "business",
                text: "For your business",
                icon: "fa-solid fa-building",
            },
            {
                id: "influencer",
                text: "Be an influencer",
                icon: "fas fa-users",
            },
        ],
    }

    const handleSelect = (id: string) => {
        context.clearData()
        context.step1Actions.setClient(id)
    }

    return (
        <div className="flex flex-col">
            <PageHeading
                title={page1Data.question}
            />
            {page1Data.answers.map(
                (question, index) => (
                    <SelectionButton
                        height="lg"
                        key={`answer${index}`}
                        selection={
                            context.surveyData
                                .step1.client
                        }
                        text={question.text}
                        icon={question.icon}
                        id={question.id}
                        handleSelect={
                            handleSelect
                        }
                    />
                ),
            )}
        </div>
    )
}

const Page2 = () => {
    const context = useContext(SurveyContext)

    const textInputProps = (
        property: keyof typeof context.surveyData.step1,
    ) => {
        return {
            value: context.surveyData.step1[
                property
            ],
            onChange: (value: string) =>
                context.step1Actions.setFormData({
                    ...context.surveyData.step1,
                    [property]: value,
                }),
        }
    }

    return (
        <div className="flex flex-col">
            <PageHeading title="Your Details" />

            {/* Business, Influencers, You */}
            <TextInput
                required
                placeholder="First Name"
                type="text"
                {...textInputProps("firstName")}
            />
            <TextInput
                required
                placeholder="Last Name"
                type="text"
                {...textInputProps("lastName")}
            />

            {/* Only Influencer */}
            {context.surveyData.step1.client ===
                "influencer" && (
                <>
                    <TextInput
                        required
                        placeholder="Date of Birth (mm/dd/yyyy)"
                        type="date"
                        {...textInputProps(
                            "dateOfBirth",
                        )}
                    />
                </>
            )}

            {/* Only You */}
            {context.surveyData.step1.client ===
                "you" && (
                <TextInput
                    required
                    placeholder="Profession"
                    type="text"
                    {...textInputProps(
                        "profession",
                    )}
                />
            )}

            {/* Only Business */}
            {context.surveyData.step1.client ===
                "business" && (
                <>
                    <TextInput
                        required
                        placeholder="Organization/Business"
                        type="text"
                        {...textInputProps(
                            "organization",
                        )}
                    />
                    <TextInput
                        required
                        placeholder="Position in Company"
                        type="text"
                        {...textInputProps(
                            "position",
                        )}
                    />
                </>
            )}

            {/* Both Business And You */}
            <TextInput
                required
                placeholder="Email"
                type="email"
                {...textInputProps("email")}
            />
            <TextInput
                required
                placeholder="Phone Number"
                type="text"
                {...textInputProps("phoneNumber")}
            />

            {/* Only Influencer */}
            {context.surveyData.step1.client ===
                "influencer" && (
                <>
                    <TextInput
                        required
                        placeholder="Social Media Handles (Instagram, YouTube, TikTok, etc.)"
                        type="text"
                        {...textInputProps(
                            "socialMediaHandles",
                        )}
                    />
                </>
            )}
        </div>
    )
}

const Page3 = () => {
    const context = useContext(SurveyContext)

    const page3Data: {
        question: string
        answers: {
            id: string
            text: string
            icon: string
        }[]
    } = {
        question: "Services you want",
        answers: [
            {
                id: "brandManagement",
                text: "Brand Management",
                icon: "fas fa-certificate",
            },
            {
                id: "socialMediaMarketing",
                text: "Social Media Marketing",
                icon: "fas fa-thumbs-up",
            },
            {
                id: "digitalMarketing",
                text: "Digital Marketing",
                icon: "fas fa-chart-simple",
            },
            {
                id: "emailMarketing",
                text: "Email Marketing",
                icon: "fas fa-envelope",
            },
            {
                id: "webDevelopment",
                text: "Web Development",
                icon: "fas fa-laptop-code",
            },
            {
                id: "design",
                text: "Design",
                icon: "fas fa-paint-brush",
            },
            {
                id: "consultancy",
                text: "Consultancy",
                icon: "fas fa-handshake",
            },
            {
                id: "photography",
                text: "Photography",
                icon: "fas fa-image",
            },
            {
                id: "videography",
                text: "Videography",
                icon: "fas fa-film",
            },
        ],
    }

    const handleSelect = (id: string) => {
        context.step2Actions.setServices(id)
    }

    return (
        <div className="flex flex-col">
            <PageHeading
                title={page3Data.question}
            />

            {page3Data.answers.map(
                (question, index) => (
                    <SelectionCheckbox
                        height="sm"
                        key={`answer${index}`}
                        selection={
                            context.surveyData
                                .step2.services
                        }
                        text={question.text}
                        icon={question.icon}
                        id={question.id}
                        handleSelect={
                            handleSelect
                        }
                    />
                ),
            )}
        </div>
    )
}

const Page4Sections = ({
    question,
    category,
}: {
    category: keyof Step2FormDataType["category"]
    question: {
        number?: number
        type: string
        question: string
        answers: string[]
    }
}) => {
    const context = useContext(SurveyContext)

    const handleCheck = (answer: string) => {
        context.step2Actions.setFormData(
            question.type,
            category,
            question.question,
            answer,
        )
    }

    const handleChange = (
        value: string,
        index: number,
    ) => {
        context.step2Actions.setFormData(
            question.type,
            category,
            question.question,
            value,
            index,
        )
    }

    const handleSelect = (
        value: SingleValue<SelectOption>,
    ) => {
        if (value?.value) {
            context.step2Actions.setFormData(
                question.type,
                category,
                question.question,
                value,
            )
        }
    }

    const handleSpecify = (
        value: string,
        index: number,
    ) => {
        context.step2Actions.setFormData(
            "specify",
            category,
            question.question,
            value,
            index,
        )
    }

    if (question.type === "select") {
        const questionValue = context.surveyData
            .step2.category[category][
            question.question
        ] as SelectOption

        return (
            <div>
                <h4 className="mt-3 font-normal text-[#A5866C]">
                    {question.question}
                </h4>
                <SelectInput
                    placeholder="Answer"
                    value={
                        context.surveyData.step2
                            .category[category][
                            question.question
                        ]
                            ? (context.surveyData
                                  .step2.category[
                                  category
                              ][
                                  question
                                      .question
                              ] as SelectOption)
                            : null
                    }
                    onChange={handleSelect}
                    options={question.answers.map(
                        (answer) => ({
                            label: answer,
                            value: answer,
                        }),
                    )}
                />

                {questionValue &&
                    questionValue.value.endsWith(
                        "___",
                    ) &&
                    [
                        ...new Array(
                            question.number || 1,
                        ),
                    ].map((_, index) => (
                        <TextInput
                            key={`${question.question}${index}`}
                            required={
                                !question.number
                            }
                            placeholder={
                                "Please specify"
                            }
                            onChange={(value) =>
                                handleSpecify(
                                    value,
                                    index,
                                )
                            }
                            type="text"
                            value={
                                typeof context
                                    .surveyData
                                    .step2
                                    .category[
                                    category
                                ][
                                    question.question +
                                        `::Specified${index}`
                                ] === "string"
                                    ? (context
                                          .surveyData
                                          .step2
                                          .category[
                                          category
                                      ][
                                          question.question +
                                              `::Specified${index}`
                                      ] as string)
                                    : ""
                            }
                        />
                    ))}
            </div>
        )
    }

    if (question.type === "checkbox") {
        const questionValue = context.surveyData
            .step2.category[category][
            question.question
        ] as Set<string>

        return (
            <div className="flex flex-col">
                <h4 className="mt-3 font-normal text-[#A5866C]">
                    {question.question}
                </h4>

                {question.answers.map(
                    (answer, index) => (
                        <SelectionCheckbox
                            height="sm"
                            key={`answer${index}`}
                            selection={
                                context.surveyData
                                    .step2
                                    .category[
                                    category
                                ][
                                    question
                                        .question
                                ]
                                    ? (context
                                          .surveyData
                                          .step2
                                          .category[
                                          category
                                      ][
                                          question
                                              .question
                                      ] as Set<string>)
                                    : ""
                            }
                            text={answer}
                            icon={""}
                            id={answer}
                            handleSelect={
                                handleCheck
                            }
                        />
                    ),
                )}

                {questionValue &&
                    questionValue.has(
                        "Other (please specify): ___",
                    ) &&
                    [
                        ...new Array(
                            question.number || 1,
                        ),
                    ].map((_, index) => (
                        <TextInput
                            key={`${question.question}${index}`}
                            required={
                                !question.number
                            }
                            placeholder={
                                "Please specify"
                            }
                            onChange={(value) =>
                                handleSpecify(
                                    value,
                                    index,
                                )
                            }
                            type="text"
                            value={
                                typeof context
                                    .surveyData
                                    .step2
                                    .category[
                                    category
                                ][
                                    question.question +
                                        `::Specified${index}`
                                ] === "string"
                                    ? (context
                                          .surveyData
                                          .step2
                                          .category[
                                          category
                                      ][
                                          question.question +
                                              `::Specified${index}`
                                      ] as string)
                                    : ""
                            }
                        />
                    ))}
            </div>
        )
    }

    return (
        <div>
            <h4 className="mt-3 font-normal text-[#A5866C]">
                {question.question}
            </h4>
            {[
                ...new Array(
                    question.number || 1,
                ),
            ].map((_, index) => (
                <TextInput
                    key={`${question.question}${index}`}
                    required={!question.number}
                    placeholder="Answer"
                    value={
                        typeof context.surveyData
                            .step2.category[
                            category
                        ][
                            question.question +
                                index
                        ] === "string"
                            ? (context.surveyData
                                  .step2.category[
                                  category
                              ][
                                  question.question +
                                      index
                              ] as string)
                            : ""
                    }
                    onChange={(value) =>
                        handleChange(value, index)
                    }
                    type="text"
                />
            ))}
        </div>
    )
}

const Page4 = (context: SurveyContextType) => {
    const services =
        context.surveyData.step2.services
    const pages = []

    if (
        services &&
        services.has("brandManagement")
    ) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Brand Management
                </h2>
                {step2Questions[
                    "Brand Management"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Brand Management"
                    />
                ))}
            </>,
        )
    }
    if (
        services &&
        services.has("socialMediaMarketing")
    ) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Social Media Marketing
                </h2>
                {step2Questions[
                    "Social Media Marketing"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Social Media Marketing"
                    />
                ))}
            </>,
        )
    }
    if (
        services &&
        services.has("digitalMarketing")
    ) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Digital Marketing
                </h2>
                {step2Questions[
                    "Digital Marketing"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Digital Marketing"
                    />
                ))}
            </>,
        )
    }
    if (
        services &&
        services.has("emailMarketing")
    ) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Email Marketing
                </h2>
                {step2Questions[
                    "Email Marketing"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Email Marketing"
                    />
                ))}
            </>,
        )
    }
    if (
        services &&
        services.has("webDevelopment")
    ) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Web Development
                </h2>
                {step2Questions[
                    "Web Development"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Web Development"
                    />
                ))}
            </>,
        )
    }
    if (services && services.has("design")) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Design
                </h2>
                {step2Questions["Design"].map(
                    (question) => (
                        <Page4Sections
                            key={
                                question.question
                            }
                            question={question}
                            category="Design"
                        />
                    ),
                )}
            </>,
        )
    }
    if (services && services.has("consultancy")) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Consultancy
                </h2>
                {step2Questions[
                    "Consultancy"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Consultancy"
                    />
                ))}
            </>,
        )
    }
    if (services && services.has("photography")) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Photography
                </h2>
                {step2Questions[
                    "Photography"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Photography"
                    />
                ))}
            </>,
        )
    }
    if (services && services.has("videography")) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Videography
                </h2>
                {step2Questions[
                    "Videography"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Videography"
                    />
                ))}
            </>,
        )
    }
    if (services && services.has("influencer")) {
        pages.push(
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Background and Experience
                </h2>
                {step2Influencers[
                    "Background and Experience"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Background and Experience"
                    />
                ))}
            </>,
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Social Media Presence
                </h2>
                {step2Influencers[
                    "Social Media Presence"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Social Media Presence"
                    />
                ))}
            </>,
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Collaboration Preferences
                </h2>
                {step2Influencers[
                    "Collaboration Preferences"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Collaboration Preferences"
                    />
                ))}
            </>,
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Professional Goals
                </h2>
                {step2Influencers[
                    "Professional Goals"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Professional Goals"
                    />
                ))}
            </>,
            <>
                <h2 className="text-xl font-semibold text-camel-dark mb-3">
                    Additional Information
                </h2>
                {step2Influencers[
                    "Additional Information"
                ].map((question) => (
                    <Page4Sections
                        key={question.question}
                        question={question}
                        category="Additional Information"
                    />
                ))}
            </>,
        )
    }

    return pages
}

function convertSetsToArrays(obj: any) {
    if (obj instanceof Set) {
        return Array.from(obj)
    } else if (
        typeof obj === "object" &&
        obj !== null
    ) {
        for (let key in obj) {
            obj[key] = convertSetsToArrays(
                obj[key],
            )
        }
    }
    return obj
}

const Survey = () => {
    const context = useContext(SurveyContext)
    const [pageNumber, setPageNumber] =
        useState(0)
    const navigate = useNavigate()
    const [submitted, setSubmitted] =
        useState(false)
    const step1 = context.surveyData.step1

    const pages = [<Page1 />, <Page2 />]

    if (!(step1.client === "influencer")) {
        pages.push(<Page3 />, ...Page4(context))
    } else {
        pages.push(...Page4(context))
    }

    const handleProgess = (
        direction: "back" | "next" | "submit",
    ) => {
        if (direction === "back") {
            setPageNumber((prev) =>
                prev > 0 ? prev - 1 : prev,
            )
        }
        if (direction === "next") {
            setPageNumber((prev) =>
                prev < pages.length - 1
                    ? prev + 1
                    : prev,
            )
        }
        if (direction === "submit") {
            setSubmitted(true)

            fetch("/api/message", {
                method: "POST",
                body: JSON.stringify(
                    convertSetsToArrays(
                        structuredClone(
                            context.surveyData,
                        ),
                    ),
                ),
            })
                .then((res) => {
                    if (res.ok) {
                        return navigate(
                            "/received",
                        )
                    }

                    throw new Error(
                        "failed to send message",
                    )
                })
                .catch((err) => {
                    // setErrorMessage(err.message)
                    console.log(err.message)
                    setSubmitted(false)
                })
        }
        global.scrollTo(0, 0)
    }

    const verifyInput = () => {
        const step1 = context.surveyData.step1
        const step2 = context.surveyData.step2

        if (pageNumber === 0 && step1.client) {
            return true
        }

        if (
            (pageNumber === 1 &&
                step1.firstName &&
                step1.lastName &&
                step1.email &&
                step1.phoneNumber &&
                step1.client === "influencer" &&
                step1.dateOfBirth &&
                step1.socialMediaHandles) ||
            (step1.client === "you" &&
                step1.profession) ||
            (step1.client === "business" &&
                step1.organization &&
                step1.position)
        ) {
            return true
        }

        if (
            pageNumber >= 2 &&
            step2.services.size > 0
        ) {
            return true
        }

        return false
    }

    return (
        <section className="contact-survey">
            <form
                onSubmit={(event) => {
                    event.preventDefault()
                    const inputs =
                        event.currentTarget.getElementsByTagName(
                            "input",
                        )
                    let isEmpty = false

                    for (
                        var i = 0;
                        i < inputs.length;
                        i++
                    ) {
                        if (
                            inputs[i].value === ""
                        ) {
                            isEmpty = true
                            break
                        }
                    }

                    const nextButton =
                        event.currentTarget.querySelector(
                            "#nextButton",
                        )

                    const submitButton =
                        event.currentTarget.querySelector(
                            "#submitButton",
                        )

                    if (nextButton) {
                        return handleProgess(
                            "next",
                        )
                    }

                    if (submitButton) {
                        return handleProgess(
                            "submit",
                        )
                    }
                }}
            >
                <div className="p-3 flex flex-col z-50">
                    <ProgressBar
                        progress={
                            (pageNumber + 1) /
                            pages.length
                        }
                    />

                    {/* Current Page */}
                    {pages[pageNumber]}
                    <span className="h-[50vh]" />
                </div>

                {/* Progress Buttons */}
                <div
                    style={{
                        boxShadow:
                            "0 -2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px",
                    }}
                    className="grid grid-cols-2 gap-6 fixed bottom-0 w-full p-3 z-50 bg-beige shadow-bs-eerie-black"
                >
                    <ProgressButton
                        id="backButton"
                        disabled={false}
                        onClick={() =>
                            handleProgess("back")
                        }
                        text="Back"
                        type="button"
                    />

                    {!(
                        pageNumber + 1 ===
                        pages.length
                    ) ? (
                        <ProgressButton
                            id="nextButton"
                            disabled={
                                !verifyInput()
                            }
                            onClick={() => {}}
                            text="Next"
                            type="submit"
                        />
                    ) : (
                        <ProgressButton
                            id="submitButton"
                            disabled={
                                (pageNumber < 3 &&
                                    !verifyInput()) ||
                                submitted
                            }
                            onClick={() => {}}
                            text="Submit"
                        />
                    )}
                </div>
            </form>
        </section>
    )
}

export default Survey
