import React from "react"
import ImageText from "../../components/ImageText"

import "./Daniel.scss"

import image from "../../assets/team/daniel.jpg"

const Daniel = () => {
    return (
        <section id="about-daniel">
            <div className="our-founder contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={image}
                    heading="Daniel da Trindade"
                    subheading="Founder and Marketing Director"
                    borderColor="#ab9483"
                    hrText={[
                        "aesthetics",
                        "about",
                    ]}
                    content={
                        <React.Fragment>
                            <p>
                                Daniel da Trindade
                                is a Creative
                                Entrepreneur,
                                Operations
                                Director,
                                Film-maker, former
                                Malawian
                                international
                                swimmer, and
                                Co-Founder of
                                Aesthetics
                                Creative Agency
                                and founder of
                                REEL.health.
                                Passionate about
                                driving creativity
                                in Malawi, he is a
                                2-time Euro Film
                                Festival
                                showcasing film
                                director with two
                                of his films being
                                screened at the
                                Euro Film Festival
                                in 2021 and 2022.
                            </p>
                        </React.Fragment>
                    }
                    alt="Daniel Da Trindade"
                />
            </div>
        </section>
    )
}

export default Daniel
