import React from "react"
import ImageText from "../../components/ImageText"

import "./Mark.scss"

import image from "../../assets/team/mark.jpg"

const Mark = () => {
    return (
        <section id="about-mark">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    heading="Mark Ulaya"
                    subheading="Web Developer"
                    borderColor="#ab9483"
                    hrText={[
                        "aesthetics",
                        "about",
                    ]}
                    content={
                        <React.Fragment>
                            <p>
                                Mark Ulaya is a
                                Web and Software
                                Developer with a
                                diverse background
                                in several
                                industry leading
                                technologies,
                                including React,
                                TypeScript, HTML,
                                CSS, Node.js, and
                                Go. With years of
                                experience in
                                these
                                technologies, he
                                has become adept
                                at creating
                                high-performance,
                                responsive, and
                                user-friendly
                                websites and
                                applications.
                            </p>
                        </React.Fragment>
                    }
                    alt="Mark Ulaya"
                />
            </div>
        </section>
    )
}

export default Mark
