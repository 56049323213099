import React from "react"
import ImageText from "../../components/ImageText"

import eventsBrandImage from "../../assets/eventsbrand.png"
import webp from "../../assets/eventsbrand.webp"

const EventsBrand = () => {
    return (
        <section id="services-eventsbrand">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    borderColor="#c7a47d"
                    src={eventsBrandImage}
                    webpSrc={webp}
                    alt="flowers"
                    hrText={["aesthetics", "home"]}
                    heading={
                        <React.Fragment>
                            EVENTS &<br />
                            BRAND ACTIVATIONS
                        </React.Fragment>
                    }
                    content={
                        <p>
                            Bring your brand to life with Aesthetics Creative
                            Agency's expert events and brand activation
                            services. From concept to execution, our team will
                            craft an unforgettable experience that showcases the
                            essence of your brand and leaves a lasting
                            impression. Whether it's a launch event, a product
                            activation, or a conference, our passion for
                            creativity and attention to detail will ensure that
                            your event is a success. Embrace the power of brand
                            activation and elevate your business to new heights.
                        </p>
                    }
                />
            </div>
        </section>
    )
}

export default EventsBrand
