import { useReducer } from "react"

interface IUseIterator {
    <T>(items: T[]): [T, () => void]
}

const useIterator: IUseIterator = (items) => {
    const [index, nextIndex] = useReducer((i) => {
        return i === items.length - 1 ? 0 : i + 1
    }, 0)

    return [items[index], nextIndex]
}

export default useIterator
