import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"

import Section1 from "./Contact/Section1"
import Section2 from "./Contact/Section2"
import Section3 from "./Contact/Section3"

const Contact = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <Section1 />
            <Section2 />
            {/* <Section3 /> */}
            <Footer />
        </React.Fragment>
    )
}

export default Contact
