import React from "react"
import { Helmet } from "react-helmet"

import Footer from "../components/Footer"
import Section1 from "./Influencers/Section1"

const Influencers = () => {
    return (
        <section id="influencers">
            <Helmet>
                <title>Influencers</title>
            </Helmet>
            <Section1 />
            <Footer />
        </section>
    )
}

export default Influencers
