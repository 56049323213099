import Select, { SingleValue } from "react-select"
import React, { useState } from "react"
import DatePicker from "react-datepicker"
import { getYear, getMonth } from "date-fns"
import range from "lodash/range"
import "react-datepicker/dist/react-datepicker.css"
import style from "./Input.module.css"

interface TextInputProps {
    required: boolean
    type: "text" | "email" | "password" | "date"
    placeholder: string
    value: string
    onChange: (value: string) => void
}

export const TextInput = ({
    required,
    type,
    placeholder,
    value,
    onChange,
}: TextInputProps) => {
    const [startDate, setStartDate] =
        useState<Date | null>(null)
    const years = range(
        1901,
        getYear(new Date()) + 1,
        1,
    )
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    return (
        <div className="mt-3">
            {type === "date" ? (
                <DatePicker
                    calendarClassName={
                        style["react-datepicker"]
                    }
                    popperClassName={style.popper}
                    required
                    placeholderText="Date of Birth"
                    className="w-full px-2 py-2 bg-[#fbfaf1] placeholder-camel-dark border border-khaki-light rounded-md focus:outline-none focus:border-camel-dark font-normal transition-colors"
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent:
                                    "center",
                                gap: 4,
                            }}
                        >
                            <select
                                className="text-center font-medium"
                                value={
                                    months[
                                        getMonth(
                                            date,
                                        )
                                    ]
                                }
                                onChange={({
                                    target: {
                                        value,
                                    },
                                }) =>
                                    changeMonth(
                                        months.indexOf(
                                            value,
                                        ),
                                    )
                                }
                            >
                                {months.map(
                                    (option) => (
                                        <option
                                            key={
                                                option
                                            }
                                            value={
                                                option
                                            }
                                        >
                                            {
                                                option
                                            }
                                        </option>
                                    ),
                                )}
                            </select>

                            <select
                                className="font-medium"
                                value={getYear(
                                    date,
                                )}
                                onChange={({
                                    target: {
                                        value,
                                    },
                                }) =>
                                    changeYear(
                                        +value,
                                    )
                                }
                            >
                                {years.map(
                                    (option) => (
                                        <option
                                            key={
                                                option
                                            }
                                            value={
                                                option
                                            }
                                        >
                                            {
                                                option
                                            }
                                        </option>
                                    ),
                                )}
                            </select>
                        </div>
                    )}
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        date &&
                            onChange(
                                date.toLocaleDateString(),
                            )
                    }}
                />
            ) : (
                <input
                    required={required}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    onChange={(e) =>
                        onChange(e.target.value)
                    }
                    className="w-full px-2 py-2 bg-[#fbfaf1] placeholder-camel-dark border border-khaki-light rounded-md focus:outline-none focus:border-camel-dark font-normal transition-colors"
                />
            )}
        </div>
    )
}

export interface SelectOption {
    value: string
    label: string
    icon?: React.ReactNode
}

interface SelectInputProps {
    placeholder: string
    value: SelectOption | null
    onChange: (
        value: SingleValue<SelectOption>,
    ) => void
    options: SelectOption[]
}

export const SelectInput = ({
    placeholder,
    value,
    onChange,
    options,
}: SelectInputProps) => {
    return (
        <div className="mt-3 ">
            <Select
                placeholder={placeholder}
                required
                value={value}
                onChange={onChange}
                options={options}
                isSearchable={false}
                getOptionLabel={(option) =>
                    option.icon
                        ? ((
                              <React.Fragment>
                                  {option.icon}
                                  {option.label}
                              </React.Fragment>
                          ) as any)
                        : option.label
                }
                getOptionValue={(option) =>
                    option.value
                }
                styles={{
                    indicatorSeparator: () => ({
                        display: "none",
                    }),
                    control: (base, state) => ({
                        ...base,
                        boxShadow: "none",
                        borderColor:
                            state.isFocused
                                ? "rgb(181 152 115)"
                                : "rgb(215 197 167)",
                        outline: "none",
                        "&:hover": {
                            borderColor: "none",
                        },
                        cursor: "pointer",
                        borderRadius: "0.375rem",
                        backgroundColor:
                            "#fbfaf1",
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: "0.25rem",
                    }),
                    placeholder: (
                        base,
                        props,
                    ) => ({
                        ...base,
                        color: "rgb(181 152 115)",
                        fontWeight: "400",
                    }),
                    menu: (base, props) => ({
                        ...base,
                        zIndex: "1000",
                        backgroundColor:
                            "#fbfaf1",
                        position: "absolute",
                    }),
                }}
            />
        </div>
    )
}
