import React from "react"

import TextHighlight from "../../components/TextHighlight"

import "./Section2.scss"

const Section2 = () => {
    return (
        <section id="home-section2">
            <div className="contain">
                <TextHighlight
                    text={
                        <React.Fragment>
                            <em>
                                “Great things are done by a series of small
                                things brought together.”
                            </em>
                            <br />— Vincent van Gogh
                        </React.Fragment>
                    }
                />
            </div>
        </section>
    )
}

export default Section2
