import React from "react"
import ImageText from "../../components/ImageText"

import "./Latitude.scss"

import webp from "../../assets/partners/latitude.webp"
import image from "../../assets/partners/latitude.jpg"

const Latitude = () => {
    return (
        <section id="ourwork-latitude">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    webpSrc={webp}
                    heading="Latitude 13°"
                    borderColor="#b7916d"
                    hrText={["aesthetics", "work"]}
                    content={
                        <React.Fragment>
                            <p>
                                Latitude 13° is a premier hotel and lifestyle
                                destination located on the northern edge of
                                Lilongwe, Malawi. Offering a variety of
                                amenities such as a 24-hour front desk, a
                                restaurant and bar, a fitness club with a
                                state-of-the-art gym and heated pool, and a
                                center for music, art, and events, Latitude 13°
                                caters to both business and leisure travelers.
                                With a commitment to sustainability and
                                fostering connections between locals and
                                visitors, Latitude 13° is a stylish and
                                socially-conscious choice for your next stay in
                                Lilongwe.
                            </p>
                        </React.Fragment>
                    }
                    alt="latitude"
                    buttonText="VIEW WORK"
                    buttonUrl="/ourwork/latitude"
                    url="/ourwork/latitude"
                    reload
                />
                {/* <ZoomCarousel
                    images={[
                        { src: showcase1, type: "image", title: "Design" },
                        { src: showcase2, type: "video", title: "Reels" },
                        {
                            src: showcase3,
                            type: "image",
                            title: "Social Media",
                        },
                    ]}
                /> */}
            </div>
        </section>
    )
}

export default Latitude
