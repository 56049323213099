import React, { useState, createRef, useEffect } from "react"
import ReactDOM from "react-dom"
import "./ZoomableImageModal.scss"

interface Props {
    src: string
    alt: string
    className: string
}

const ZoomableImageModal: React.FC<Props> = ({ src, alt, className }) => {
    const [isOpen, setIsOpen] = useState(false)
    const modalRoot = document.getElementById("global-modal")
    const modalRef = createRef<HTMLDivElement>()

    useEffect(() => {
        const node = modalRef.current
        if (node && modalRoot) {
            modalRoot.appendChild(node)
        }
        return () => {
            if (node && modalRoot) {
                // modalRoot.removeChild(node)
            }
        }
    }, [modalRef, modalRoot])

    const handleOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <React.Fragment>
            <img
                className={`zoomable-img ${className}`}
                src={src}
                onClick={handleOpen}
                alt={alt}
            />
            {isOpen && modalRoot
                ? ReactDOM.createPortal(
                      <div className="modal" ref={modalRef}>
                          <img src={src} alt="Zoomable" />
                          <button
                              className="close-button"
                              onClick={handleClose}
                          >
                              <i className="fa fa-times" aria-hidden="true"></i>
                          </button>
                      </div>,
                      modalRoot
                  )
                : null}
        </React.Fragment>
    )
}

export default ZoomableImageModal
