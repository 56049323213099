import React from "react"

import "./Footer.scss"

import logo from "../assets/logo-light.svg"

const Footer = () => {
    return (
        <section id="footer">
            <div className="contain">
                <div className="footer-container">
                    <div className="row">
                        <div className="footer-section col-lg-4 col-md-4 col-sm-12">
                            A group of young
                            African creatives on a
                            mission to help brands
                            discover their brand
                            voice.
                            <img
                                className="footer-logo"
                                src={logo}
                                alt="aesthetics creative agency logo"
                            />
                        </div>
                        <div className="footer-section col-lg-4 col-md-4 col-sm-12">
                            Aesthetics Creative
                            Agency
                            <br />
                            Plot 253
                            <br /> Mwanza, Area 15
                            <br /> Lilongwe,
                            Malawi
                        </div>
                        <div className="footer-section col-lg-4 col-md-4 col-sm-12">
                            Connect with us
                            <div className="social-logos">
                                <a
                                    href="https://www.facebook.com/aestheticscreativeagency"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a
                                    href="https://www.instagram.com/aestheticscreativeagency"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/aesthetics-creative-agency"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fa-brands fa-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="footer-bottom row">
                        Aesthetics Creative Agency
                        &copy;{" "}
                        {new Date(
                            Date.now(),
                        ).getFullYear()}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
