import React from "react"
import ImageText from "../../components/ImageText"

import "./Khala.scss"

import image from "../../assets/partners/khala.jpg"
import webp from "../../assets/partners/khala.webp"

const Khala = () => {
    return (
        <section id="ourwork-khala">
            <div className="contain">
                <ImageText
                    direction="invert"
                    textAlign="left"
                    src={image}
                    webpSrc={webp}
                    borderColor="#b7916d"
                    heading="Khala"
                    hrText={["aesthetics", "work"]}
                    content={
                        <p>
                            Khala is a tapas restaurant and wine-bar located at
                            the Four Seasons complex. A perfect spot to relax
                            and unwind, Khala offers a variety of delicious
                            food, drinks, and entertainment in a beautiful open
                            space. Enjoy the positive vibes and indulge in a
                            selection of tapas, wines, coffee and cocktails.
                        </p>
                    }
                    alt="khala"
                    buttonText="VIEW WORK"
                    buttonUrl="/ourwork/khala"
                    url="/ourwork/khala"
                    reload
                />
                {/* <ZoomCarousel
                    images={[
                        { src: showcase1, type: "image", title: "Photography" },
                        { src: showcase2, type: "video", title: "Reels" },
                        {
                            src: showcase3,
                            type: "image",
                            title: "Social Media",
                        },
                    ]}
                /> */}
            </div>
        </section>
    )
}

export default Khala
