import React from "react"

import "./BackgroundImage.scss"

interface BackgroundImageProps {
    children: React.ReactNode
    src: string
}

const BackgroundImage = ({ children, src }: BackgroundImageProps) => {
    return (
        <React.Fragment>
            <div
                className="background-image"
                style={{ backgroundImage: `url(${src})` }}
            >
                <div className="background-image-backdrop">
                    <div className="background-image-content">{children}</div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BackgroundImage
