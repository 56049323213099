import React from "react"
import { HashLink as Link } from "react-router-hash-link"

import "./Section10.scss"

import latitudeLogo from "../../assets/partners/latitude.jpg"
import latitudeLogoWebp from "../../assets/partners/latitude.webp"

import khichinihubLogo from "../../assets/partners/khichinihub.jpg"
import khichinihubLogoWebp from "../../assets/partners/khichinihub.webp"

import butcheryLogo from "../../assets/partners/yourmeatguys.jpg"
import butcheryLogoWebp from "../../assets/partners/yourmeatguys.webp"

import khalaLogo from "../../assets/partners/khala.jpg"
import khalaLogoWebp from "../../assets/partners/khala.webp"

import marcusHillLogo from "../../assets/partners/marcushill.jpg"
import marcusHillLogoWebp from "../../assets/partners/marcushill.webp"

import IMG from "../../components/IMG"

const Section10 = () => {
    return (
        <section id="home-section10">
            <div className="partners contain">
                <h2>OUR CLIENTS</h2>
                <div className="partner-images">
                    <Link to="/ourwork#ourwork-yourmeatguys">
                        <IMG
                            webpSrc={
                                butcheryLogoWebp
                            }
                            imgSrc={butcheryLogo}
                            alt="your meat guys logo"
                        />
                    </Link>
                    <Link to="/ourwork#ourwork-khichinihub">
                        <IMG
                            webpSrc={
                                khichinihubLogoWebp
                            }
                            imgSrc={
                                khichinihubLogo
                            }
                            alt="khichini hub logo"
                        />
                    </Link>
                    <Link to="/ourwork#ourwork-latitude">
                        <IMG
                            webpSrc={
                                latitudeLogoWebp
                            }
                            imgSrc={latitudeLogo}
                            alt="latitude logo"
                        />
                    </Link>
                    <Link to="/ourwork#ourwork-marcushill">
                        <IMG
                            webpSrc={
                                marcusHillLogoWebp
                            }
                            imgSrc={
                                marcusHillLogo
                            }
                            alt="marcus hill logo"
                        />
                    </Link>
                    <Link to="/ourwork#ourwork-khala">
                        <IMG
                            webpSrc={
                                khalaLogoWebp
                            }
                            imgSrc={khalaLogo}
                            alt="khala logo"
                        />
                    </Link>
                </div>
            </div>
            {/* TrustBox widget - Micro Review Count */}
            <div
                className="trustpilot-widget"
                data-locale="en-US"
                data-template-id="5419b6a8b0d04a076446a9ad"
                data-businessunit-id="64693f45bd18044979790703"
                data-style-height="48px"
                data-style-width="100%"
                data-theme="light"
                data-min-review-count="10"
                data-without-reviews-preferred-string-id="1"
                data-style-alignment="center"
            >
                <a
                    href="https://www.trustpilot.com/review/www.aestheticscreativeagency.com"
                    target="_blank"
                    rel="noopener"
                >
                    Trustpilot
                </a>
            </div>
            {/* End TrustBox widget */}
        </section>
    )
}

export default Section10
