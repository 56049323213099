import React from "react"
import { HashLink as Link } from "react-router-hash-link"
import AestheticHr from "./AestheticHr"

import "./ImageText.scss"
import IMG from "./IMG"
import ZoomableImageModal from "./ZoomableImageModal"

interface ImageTextProps {
    src: string
    type?: "image" | "video"
    alt: string
    heading: React.ReactNode
    content: React.ReactNode
    direction: "normal" | "invert"
    textAlign: "left" | "right"
    webpSrc?: string
    subheading?: string
    buttonText?: string
    buttonUrl?: string
    borderColor?: string
    hrText: [string, string]
    showHr?: boolean
    map?: React.ReactNode
    renderError?: string
    reload?: boolean
    showBorder?: boolean
    zoomable?: boolean
    url?: string
}

const Image = (
    src: string,
    type: "image" | "video",
    alt: string,
    borderColor: string,
    showBorder: boolean,
    textAlign: "left" | "right",
    zoomable: boolean,
    url: string,
    webpSrc: string,
    map?: React.ReactNode
) => {
    return (
        <div className="col-lg-6 col-md-6 col-sm-12 fill-grid">
            <div
                className={`imageText-image ${
                    textAlign === "right" ? "image-left" : "image-right"
                }`}
            >
                <div
                    className={showBorder ? "image-border" : "no-border"}
                    style={{ borderColor }}
                >
                    {map ? (
                        map
                    ) : type === "video" ? (
                        <video className="card" src={src} controls />
                    ) : zoomable ? (
                        <ZoomableImageModal
                            className="card"
                            src={src}
                            alt={alt}
                        />
                    ) : url ? (
                        <Link to={url} reloadDocument>
                            {/* <img className="card" src={src} alt={alt} /> */}
                            <IMG
                                className="card"
                                imgSrc={src}
                                alt={alt}
                                webpSrc={webpSrc}
                            />
                        </Link>
                    ) : (
                        // <img className="card" src={src} alt={alt} />
                        <IMG
                            className="card"
                            imgSrc={src}
                            alt={alt}
                            webpSrc={webpSrc}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

const Text = (
    heading: React.ReactNode,
    subheading: string,
    content: React.ReactNode,
    textAlign: "left" | "right",
    hrText: [string, string],
    showHr: boolean,
    reload: boolean,
    renderError?: string,
    buttonText?: string,
    buttonUrl?: string
) => {
    return (
        <div className="col-lg-6 col-md-6 col-sm-12 fill-grid">
            <div className={`imageText-text`}>
                <div
                    className={`${
                        textAlign === "left"
                            ? "left-align-imageText"
                            : "right-align-imageText"
                    }`}
                >
                    <h3 style={{ textAlign: textAlign }}>{heading}</h3>
                    {subheading ? <h4>{subheading}</h4> : null}
                    {renderError ? (
                        <div className="error-message">{renderError}</div>
                    ) : (
                        ""
                    )}
                    <div className="text" style={{ textAlign: textAlign }}>
                        {content}
                    </div>
                    {buttonText ? (
                        <Link
                            className="more-button"
                            to={buttonUrl ? buttonUrl : "#"}
                            reloadDocument={reload}
                        >
                            {buttonText}
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
                {showHr ? (
                    <AestheticHr
                        text1={hrText[0]}
                        text2={hrText[1]}
                        className="hr-text"
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}

const ImageText = ({
    src,
    type = "image",
    map,
    alt,
    heading,
    content,
    direction,
    textAlign,
    webpSrc = "",
    buttonText,
    buttonUrl,
    borderColor = "#1d1c1a",
    hrText,
    renderError,
    subheading = "",
    showHr = true,
    reload = false,
    showBorder = true,
    zoomable = false,
    url = "",
}: ImageTextProps) => {
    return (
        <div className="imageText">
            <div className="row">
                {direction === "normal" ? (
                    <React.Fragment>
                        {Image(
                            src,
                            type,
                            alt,
                            borderColor,
                            showBorder,
                            textAlign,
                            zoomable,
                            url,
                            webpSrc,
                            map
                        )}
                        {Text(
                            heading,
                            subheading,
                            content,
                            textAlign,
                            hrText,
                            showHr,
                            reload,
                            renderError,
                            buttonText,
                            buttonUrl
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {Text(
                            heading,
                            subheading,
                            content,
                            textAlign,
                            hrText,
                            showHr,
                            reload,
                            renderError,
                            buttonText,
                            buttonUrl
                        )}
                        {Image(
                            src,
                            type,
                            alt,
                            borderColor,
                            showBorder,
                            textAlign,
                            zoomable,
                            url,
                            webpSrc,
                            map
                        )}
                    </React.Fragment>
                )}
            </div>

            {showHr ? (
                <AestheticHr
                    className="hr-row"
                    text1={hrText[0]}
                    text2={hrText[1]}
                />
            ) : (
                ""
            )}
        </div>
    )
}

export default ImageText
