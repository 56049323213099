import React from "react"
import ImageText from "../../components/ImageText"

import "./MonaLisa.scss"

import image from "../../assets/team/monalisa.jpg"

const MonaLisa = () => {
    return (
        <section id="about-monalisa">
            <div className="our-founder2 contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    borderColor="#ab9483"
                    heading="Mona Lisa"
                    subheading="Founder and Creative Director"
                    hrText={["aesthetics", "about"]}
                    content={
                        <p>
                            Mona Lisa is a DJ, Creative Director, Strategist,
                            Creative Entrepreneur, A&R and Co-founder of
                            Aesthetics Creative Agency and GxrlCode, a creative
                            collective based in Ireland. A person of incredible
                            talent, she is a PSR Keychange-maker and was
                            recently inducted into Forbes Africa
                            30under30 Class of '22.
                        </p>
                    }
                    alt="Mona Lisa"
                />
            </div>
        </section>
    )
}

export default MonaLisa
