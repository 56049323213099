// Importing required modules and data
import React, {
    createContext,
    ReactNode,
    useState,
} from "react"
import step2Questions from "../data/step2Questions.json"
import step2Influencers from "../data/step2Influencers.json"
import { SelectOption } from "../../../components/Input"

// Defining interfaces for form data
interface Step1FormDataType {
    client: string
    firstName: string
    lastName: string
    dateOfBirth: string
    profession: string
    organization: string
    position: string
    email: string
    phoneNumber: string
    socialMediaHandles: string
}

export interface Step2FormDataType {
    services: Set<string>
    category: {
        [key in
            | keyof typeof step2Questions
            | keyof typeof step2Influencers]: {
            [key: string]:
                | Set<string>
                | string
                | SelectOption
        }
    }
}

// Defining the structure of survey data
interface SurveyData {
    step1: Step1FormDataType
    step2: Step2FormDataType
}

// Defining the structure of survey context
export interface SurveyContextType {
    surveyData: SurveyData
    clearData: () => void
    step1Actions: {
        setClient: (client: string) => void
        setFormData: (
            step1FormData: Step1FormDataType,
        ) => void
    }
    step2Actions: {
        setServices: (service: string) => void
        setFormData: (
            type: string,
            category: keyof Step2FormDataType["category"],
            question: string,
            answer: string | SelectOption,
            index?: number,
        ) => void
    }
}

// Initial form data for each step
const initialStep1FormData: Step1FormDataType = {
    client: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    profession: "",
    organization: "",
    position: "",
    email: "",
    phoneNumber: "",
    socialMediaHandles: "",
}

const initialStep2FormData: Step2FormDataType = {
    services: new Set(),
    category: {
        "Brand Management": {},
        "Social Media Marketing": {},
        "Digital Marketing": {},
        "Email Marketing": {},
        "Web Development": {},
        Design: {},
        Consultancy: {},
        Photography: {},
        Videography: {},
        "Background and Experience": {},
        "Social Media Presence": {},
        "Collaboration Preferences": {},
        "Professional Goals": {},
        "Additional Information": {},
    },
}

// Initial survey data
const initialSurveyData: SurveyData = {
    step1: initialStep1FormData,
    step2: initialStep2FormData,
}

// Helper function to create a set action for a given step key
const createSetAction =
    (
        setState: React.Dispatch<
            React.SetStateAction<SurveyData>
        >,
        stepKey: keyof SurveyData,
    ) =>
    (data: any) => {
        setState((prev) => ({
            ...prev,
            [stepKey]: data,
        }))
    }

// Initial step 1 actions
const initialStep1Actions = (
    setState: React.Dispatch<
        React.SetStateAction<SurveyData>
    >,
) => ({
    setClient: (client: string) => {
        const services = new Set<string>(
            client === "influencer"
                ? ["influencer"]
                : [],
        )

        setState((prev) => ({
            ...prev,
            step1: { ...prev.step1, client },
            step2: { ...prev.step2, services },
        }))
    },
    setFormData: createSetAction(
        setState,
        "step1",
    ),
})

// Initial step 2 actions
const initialStep2Actions = (
    setState: React.Dispatch<
        React.SetStateAction<SurveyData>
    >,
) => ({
    setServices: (service: string) => {
        setState((prev) => {
            const newSet = new Set(
                prev.step2.services,
            )
            if (newSet.has(service)) {
                newSet.delete(service)
            } else {
                newSet.add(service)
            }
            return {
                ...prev,
                step2: {
                    ...prev.step2,
                    services: newSet,
                },
            }
        })
    },

    setFormData: (
        type: string,
        category: keyof Step2FormDataType["category"],
        question: string,
        answer: string | SelectOption,
        index?: number,
    ) => {
        if (type === "checkbox") {
            setState((prev) => {
                const newSet = new Set(
                    prev.step2.category[category][
                        question
                    ] as Set<string>,
                )

                if (typeof answer === "string") {
                    if (newSet.has(answer)) {
                        newSet.delete(answer)
                    } else {
                        newSet.add(answer)
                    }
                }

                return {
                    ...prev,
                    step2: {
                        ...prev.step2,
                        category: {
                            ...prev.step2
                                .category,
                            [category]: {
                                ...prev.step2
                                    .category[
                                    category
                                ],
                                [question]:
                                    newSet,
                            },
                        },
                    },
                }
            })
        }

        if (type === "input") {
            setState((prev) => {
                if (
                    !prev.step2.category[
                        category
                    ][question + index]
                ) {
                    prev.step2.category[category][
                        question + index
                    ] = ""
                }

                return {
                    ...prev,
                    step2: {
                        ...prev.step2,
                        category: {
                            ...prev.step2
                                .category,
                            [category]: {
                                ...prev.step2
                                    .category[
                                    category
                                ],
                                [question +
                                index]: answer,
                            },
                        },
                    },
                }
            })
        }

        if (type === "select") {
            setState((prev) => {
                if (
                    !prev.step2.category[
                        category
                    ][question]
                ) {
                    prev.step2.category[category][
                        question
                    ] = ""
                }

                return {
                    ...prev,
                    step2: {
                        ...prev.step2,
                        category: {
                            ...prev.step2
                                .category,
                            [category]: {
                                ...prev.step2
                                    .category[
                                    category
                                ],
                                [question]:
                                    answer,
                            },
                        },
                    },
                }
            })
        }

        if (type === "specify") {
            setState((prev) => {
                if (
                    !prev.step2.category[
                        category
                    ][
                        question +
                            `::Specified${
                                index !== 66
                                    ? index
                                    : ""
                            }`
                    ]
                ) {
                    prev.step2.category[category][
                        question +
                            `::Specified${
                                index !== 66
                                    ? index
                                    : ""
                            }`
                    ] = ""
                }

                return {
                    ...prev,
                    step2: {
                        ...prev.step2,
                        category: {
                            ...prev.step2
                                .category,
                            [category]: {
                                ...prev.step2
                                    .category[
                                    category
                                ],
                                [question +
                                `::Specified${index}`]:
                                    answer,
                            },
                        },
                    },
                }
            })
        }

        // if (
        //     type === "colors" ||
        //     type === "fonts" ||
        //     type === "imageryStyle"
        // ) {
        //     setState((prev) => {
        //         if (
        //             !prev.step2.category[
        //                 category
        //             ][question + `::${type}`]
        //         ) {
        //             prev.step2.category[category][
        //                 question + `::${type}`
        //             ] = ""
        //         }

        //         return {
        //             ...prev,
        //             step2: {
        //                 ...prev.step2,
        //                 category: {
        //                     ...prev.step2
        //                         .category,
        //                     [category]: {
        //                         ...prev.step2
        //                             .category[
        //                             category
        //                         ],
        //                         [question +
        //                         `::${type}`]:
        //                             answer,
        //                     },
        //                 },
        //             },
        //         }
        //     })
        // }
    },
})

// Creating the survey context
export const SurveyContext =
    createContext<SurveyContextType>({
        surveyData: initialSurveyData,
        clearData: () => {},
        step1Actions: initialStep1Actions(
            () => {},
        ),
        step2Actions: initialStep2Actions(
            () => {},
        ),
    })

// Survey context provider component
export const SurveyContextProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    const [state, setState] =
        useState<SurveyData>(initialSurveyData)

    return (
        <SurveyContext.Provider
            value={{
                surveyData: state,
                clearData: () => {
                    setState(initialSurveyData)
                },
                step1Actions:
                    initialStep1Actions(setState),
                step2Actions:
                    initialStep2Actions(setState),
            }}
        >
            {children}
        </SurveyContext.Provider>
    )
}
