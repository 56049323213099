import React from "react"

import "./Section1.scss"

import Header from "../../components/Header"
import Greeting from "../../components/Greeting"
import EnterButton from "../../components/EnterButton"

const Section1 = () => {
    return (
        <section id="home-section1">
            <div className="home-section1-cover">
                <div className="home-section1-content">
                    <Header heading="Aesthetics" subHeading="Creative Agency" />
                    <Greeting />
                    <EnterButton elementID="home-section2" />
                </div>
            </div>
        </section>
    )
}

export default Section1
