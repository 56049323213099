import React from "react"
import BackgroundImage from "../components/BackgroundImage"
import Header from "../components/Header"

import "./NotFound.scss"

import background from "../assets/homecover.jpg"

const NotFound = () => {
    return (
        <section id="not-found">
            <BackgroundImage
                src={background}
                children={
                    <Header heading="404" subHeading="Resource Not Found" />
                }
            />
        </section>
    )
}

export default NotFound
