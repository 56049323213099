import React from "react"

import "./AestheticHr.scss"

interface AestheticHrProps {
    className: string
    text1: string
    text2: string
}

const AestheticHr = ({ className, text1, text2 }: AestheticHrProps) => {
    return (
        <div className={`aesthetic-hr ${className}`}>
            {text1} <hr /> {text2}
        </div>
    )
}

export default AestheticHr
