import React from "react"
import ImageText from "../../components/ImageText"

import "./Desire.scss"

import image from "../../assets/team/desire.jpg"

const Desire = () => {
    return (
        <section id="about-desire">
            <div className="contain">
                <ImageText
                    direction="normal"
                    textAlign="right"
                    src={image}
                    heading="Desire Mtosa"
                    subheading="Lead Designer"
                    borderColor="#ab9483"
                    hrText={["aesthetics", "about"]}
                    content={
                        <React.Fragment>
                            <p>
                                Desire is a certified graphic designer through
                                the MasterCard Scholars program who has a
                                penchant for producing eye-catching designs. He
                                has more than 4 years of professional experience
                                in the field and has developed his talents
                                through a combination of practical experience
                                and ongoing learning. Desire is proficient in
                                industry standard design software, such as Adobe
                                Photoshop, Illustrator, and InDesign.
                            </p>
                        </React.Fragment>
                    }
                    alt="Desire Mtosa"
                />
            </div>
        </section>
    )
}

export default Desire
