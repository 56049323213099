import React from "react"

import "./TextHighlight.scss"

interface TextHighlightProps {
    text: React.ReactNode
}

const TextHighlight = ({ text }: TextHighlightProps) => {
    return (
        <section>
            <div className="text-highlight">
                <h2>{text}</h2>
            </div>
        </section>
    )
}

export default TextHighlight
